import request from '@/utils/oms-request'
import requestApi from '@/utils/api-request'
import requestWms from '@/utils/request'

/**
 *
 * @description
 * @export Promise
 * @returns Json
 */
// const basicdataApi = process.env.VUE_APP_CLIENT_API
// 192.168.1.214:9092
// oms.wangoon.cn
// oms.wangoon.cn
// 自发货分页查询
export function getOmsSelfDeliveryOrderItemTwoLevel(params) {
  return request({
    url: `oms/self-delivery-order/getOmsSelfDeliveryOrderItemTwoLevel`,
    method: 'get',
    params,
    timeout: 99999999
  })
}

// http://192.168.1.11:8082分页查询
export function listOmsSelfDeliveryOrderByPage(params) {
  return request({
    url: `oms/self-delivery-order/listOmsSelfDeliveryOrderByPage`,
    method: 'get',
    params,
    timeout: 99999999
  })
}

export function exportTrackNo(data) {
  return request({
    url: `oms/self-delivery-order/exportSelfOrderTrackingInfo`,
    method: 'post',
    data
  })
}

// 自发货展开展开详情查询
export function listOmsSelfDeliveryOrderItemSplit(params) {
  return request({
    url: `oms/self-delivery-order/listOmsSelfDeliveryOrderItemSplit`,
    method: 'get',
    params
  })
}

// 自发货导出
export function exportOmsSelfDeliveryOrder() {
  return request({
    url: `oms/self-delivery-order/exportOmsSelfDeliveryOrder`,
    method: 'get'
  })
}

// 批量分配
export function allocationorders(data) {
  return request({
    url: `oms/self-delivery-order/allocation-orders`,
    method: 'post',
    data,
    timeout: 99999999
  })
}

// 批量作废
export function cancelorders(data) {
  return request({
    url: `oms/self-delivery-order/cancel-orders`,
    method: 'post',
    data
  })
}

export function batchTransit(data) {
  return request({
    url: `oms/self-delivery-order/batchToPendingConverter`,
    method: 'post',
    data
  })
}

// 修改
export function modifyorder(data) {
  return request({
    url: `oms/self-delivery-order/modify-order`,
    method: 'post',
    data
  })
}

// 自发货订单单个查询
export function getOmsSelfDeliveryOrder(params) {
  return request({
    url: `oms/self-delivery-order/getOmsSelfDeliveryOrder`,
    method: 'get',
    params
  })
}

// 手动分配仓库
export function handSplitWarehouse(data) {
  return request({
    url: `oms/self-delivery-order/handSplitWarehouse`,
    method: 'post',
    data
  })
}

// 同步订单
export function handsyncorders(data) {
  return request({
    url: `oms/self-delivery-order/hand-sync-orders`,
    method: 'post',
    data
  })
}

// 仓库查询
export function wareHouse(params) {
  return request({
    url: `oms/self-delivery-order/getSelectItemsForWareHouseListByCondition`,
    method: 'get',
    params
  })
}

// 出货仓库查询
export function getSelectItemsForWareHouseList(params) {
  return request({
    url: `oms/self-delivery-order/getSelectItemsForWareHouseList`,
    method: 'get',
    params
  })
}

// 自发货库存查询
export function selectUploadRecord(params) {
  return request({
    url: `oms/skuStockUpload/selectUploadRecord`,
    method: 'get',
    params
  })
}

// 上传库存
export function uploadRecord(data) {
  return request({
    url: `oms/skuStockUpload/uploadRecord`,
    method: 'post',
    data
  })
}

// 库存导出
export function StockUploadexport(params) {
  return request({
    url: `oms/skuStockUpload/export`,
    method: 'get',
    params
  })
}

// 站点条件查询
export function querySiteList(params) {
  return requestWms({
    url: `base/basesite/listByPlatform`,
    method: 'get',
    params
  })
}

// 平台条件查询
export function queryTerraceList(params) {
  return requestWms({
    url: `base/baseterrace/list`,
    method: 'get',
    params
  })
}

// Style信息查询
export function queryStyleList(params) {
  return requestWms({
    url: `base/basestyle/list`,
    method: 'get',
    params
  })
}

// 通过Style ID查询sku生成需要的数据
export function querySkuListByStyle(id) {
  return requestWms({
    url: `base/basesku/queryStyleInfoAndAttr/${id}`,
    method: 'get'
  })
}

// Size信息查询
export function querySizeList(params) {
  return requestWms({
    url: `base/basesize/list`,
    method: 'get',
    params
  })
}

// Color信息查询
export function queryColorList(params) {
  return requestWms({
    url: `base/basecolor/list`,
    method: 'get',
    params
  })
}

// 发运单包裹信息批量查询
export function OrderShipmentPackage(params) {
  return request({
    url: `oms/fulfillment_order/listFulfillmentOrderShipmentPackage`,
    method: 'get',
    params
  })
}

// 发运单包裹明细信息批量查询
export function OrderShipmentPackageDetails(params) {
  return request({
    url: `oms/fulfillment_order/listFulfillmentOrderShipmentPackageDetails`,
    method: 'get',
    params
  })
}

// 发运单信息查询参数
export function listFulfillmentOrderShipment(params) {
  return request({
    url: `oms/fulfillment_order/listFulfillmentOrderShipment`,
    method: 'get',
    params
  })
}

// 自发货订单混合类型批量转FBA类型
export function bleadToFbaForOmsOrder(data) {
  return request({
    url: `oms/self-delivery-order/bleadToFbaForOmsOrder`,
    method: 'post',
    data
  })
}

// Fba发运单取消
export function cancelfulfillmentorder(data) {
  return request({
    url: `oms/fulfillment_order/cancel-fulfillment-order`,
    method: 'post',
    data
  })
}

// 获取商户
export function getSelectItems(params) {
  return request({
    url: `oms/self-delivery-order/getSelectItemsForMerchantList`,
    method: 'get',
    params
  })
}

// 自发货库存查询新版本
export function selectUploadRecordV2(params) {
  return request({
    url: `oms/skuStockUpload/selectUploadRecordV2`,
    method: 'get',
    params
  })
}

// 自发货库存查询新版本
export function listAllAccount() {
  return request({
    url: `oms/skuStockUpload/listAllAccount`,
    method: 'get'
  })
}

// 修改Ebay账号
export function updateEbayAccount(data) {
  return request({
    url: `oms/skuStockUpload/updateEbayAccount`,
    method: 'POST',
    data
  })
}

// 上传库存新版本
export function uploadStock(data) {
  return request({
    url: `oms/skuStockUpload/uploadStock`,
    method: 'POST',
    data
  })
}

// 平台库存汇总导出
export function stockExport(params) {
  return request({
    url: `oms/skuStockUpload/stockExport`,
    method: 'get',
    params
  })
}

// 平台库存导入
export function stockImport(params) {
  return request({
    url: `oms/skuStockUpload/stockImport`,
    method: 'get',
    params
  })
}

// 查看操作日志
export function listOmsSelfDeliveryOrderOperationLog(params) {
  return request({
    url: `oms/self-delivery-order/listOmsSelfDeliveryOrderOperationLog`,
    method: 'get',
    params
  })
}

// 自发货订单单个查询
export function getOmsSelfDeliveryOrderItemSplitTwoLevel(params) {
  return request({
    url: `oms/self-delivery-order/getOmsSelfDeliveryOrderItemSplitTwoLevel`,
    method: 'get',
    params,
    timeout: 99999999
  })
}

// 分页查询订单统计
export function listOmsSelfDeliveryOrderByPageStatusGroup(params) {
  return request({
    url: `oms/self-delivery-order/listOmsSelfDeliveryOrderByPageStatusGroup`,
    method: 'get',
    params,
    timeout: 99999999
  })
}

// 自发货订单调整或更换sku
export function replaceSku(data) {
  return request({
    url: `oms/self-delivery-order/replaceSku`,
    method: 'POST',
    data
  })
}

// 更换SKU
// /oms/returns/getSkuAllocation
export function getSkuAllocation(params) {
  return request({
    url: `oms/returns/getSkuAllocation`,
    method: 'get',
    params
  })
}

// 库存待调
export function syncStockAndCheckStock(data) {
  return request({
    url: `oms/self-delivery-order/syncStockAndCheckStock`,
    method: 'post',
    data
  })
}

// 明细的需求备注
export function itemDemand(data) {
  return request({
    url: `/oms/self-delivery-order/itemDemand`,
    method: 'post',
    data
  })
}

// ${oms_base}

// RMA列表数据获取
export function getReturnsInfos(params, signal) {
  return request({
    url: `oms/returns/getReturnsInfos`,
    method: 'get',
    params,
    signal
  })
}

// 根据ID获取批量退货列表
export function getReturnsInfosByIds(data) {
  return request({
    url: `oms/returns/batchReturnQuery`,
    method: 'post',
    data
  })
}

// 批量退款
export function batchRefund(data) {
  return request({
    url: `oms/returns/batchReturn`,
    method: 'post',
    data
  })
}

// RMA状态数量获取
export function getAllStatusCounts(params) {
  return request({
    url: `oms/returns/getAllStatusCount`,
    method: 'get',
    params
  })
}

// 通过平台订单获取对应得数据信息（如平台站点店铺）

export function getOmsSelfDeliveryOrderItemTwoLevelGetAmount(params) {
  return request({
    url: `oms/returns/getOmsSelfDeliveryOrderItemTwoLevelGetAmount`,
    method: 'get',
    params
  })
}

export function listSysBaseConfig(data) {
  return request({
    url: `oms-job/bases/listSysBaseConfig`,
    method: 'POST',
    data
  })
}

// ${oms_base}
export function saveReturnInfos(data) {
  return request({
    url: `oms/returns/saveReturnInfos`,
    method: 'POST',
    data,
    timeout: 99999999
  })
}

// 获取退款原因
export function getAllReasonCode(platform) {
  return request({
    url: `oms/returns/getAllReasonCode/${platform}`,
    method: 'get'
  })
}

// 更新跟踪单号
export function updateTrackingNumber(data) {
  return request({
    url: `/oms/returns/updateTrackingIdAndCarrier`,
    method: 'POST',
    data
  })
}

//
export function updateStatus(data) {
  return request({
    url: `oms/returns/updateStatus`,
    method: 'POST',
    data
  })
}

export function checkReturnInfos(data) {
  return request({
    url: `oms/returns/checkReturnInfos`,
    method: 'POST',
    data
  })
}

// 通过ID查询退货单信息
export function getDetailReturnsInfoById(id) {
  return request({
    url: `oms/returns/getDetailReturnsInfoById/${id}`,
    method: 'get'
  })
}

// 切换库存待调
// /oms/self-delivery-order/changeToAdjustedStock
export function changeToAdjustedStock(data) {
  return request({
    url: `oms/self-delivery-order/changeToAdjustedStock`,
    method: 'POST',
    data,
    timeout: 999999
  })
}

// ebay购买标签
export function buyEbayLabel(params) {
  return request({
    url: `oms/returns/buyEbayLabel`,
    method: 'get',
    params
  })
}

// 获取退款失败原因
export function getErrorMsgById(id) {
  return request({
    url: `/oms/returns/getErrorMsgById/${id}`,
    method: 'get'
  })
}

// 0519 批量手动分配仓库
export function batchHandSplitWarehouse(data) {
  return request({
    url: `/oms/self-delivery-order/batchHandSplitWarehouse`,
    method: 'POST',
    data,
    timeout: 999999
  })
}

// 订单管理-分页查询
export function orderInfo(data) {
  return requestApi({
    url: `oms-rest/orderInfo/page`,
    method: 'post',
    data
  })
}

// 订单详情
export function orderInfoDetail(params) {
  return requestApi({
    url: `oms-rest/orderInfo/detail`,
    method: 'get',
    params
  })
}

// 新增订单
export function saveOrUpdate(data) {
  return requestApi({
    url: `oms-rest/orderInfo/saveOrUpdate`,
    method: 'POST',
    data
  })
}

// 新增订单
export function insertKOL(data, onUploadProgress = () => {
}) {
  return requestApi({
    url: `oms-rest/orderInfo/insertKOL`,
    method: 'POST',
    data,
    onUploadProgress
  })
}

// sku查询
export function getSkuByParams(params) {
  return requestWms({
    url: `/base/basesku/page`,
    method: 'get',
    params
  })
}

export function uploadOrder(data) {
  return requestApi({
    url: `oms-rest/orderInfo/checkImportFile`,
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data,
    timeout: 60 * 1000 * 5
  })
}

// 店铺查询
export function queryShopList(params) {
  return requestWms({
    url: `/base/platformsiteshop/list`,
    method: 'get',
    params
  })
}

// bu查询
export function queryBaseBuList(params) {
  return requestWms({
    url: `/base/basebu`,
    method: 'get',
    params
  })
}

// 订单详情
export function orderInfoClose(id) {
  return requestApi({
    url: `ordermana/orderInfo/close/${id}`,
    method: 'get'
  })
}

export function getSameStyleColorSize(params) {
  return requestWms({
    url: `/base/basesku/getSameStyleColorSize`,
    method: 'get',
    params
  })
}

export function sameStyleColorSize(sku, data) {
  return requestWms({
    url: `/base/basesku/sameStyleColorSize/${sku}`,
    method: 'POST',
    data
  })
}

export function repairSku(data) {
  return request({
    url: `oms/self-delivery-order/repairSku`,
    method: 'POST',
    data
  })
}

export function modifyCustInfo(data) {
  return request({
    url: `oms/self-delivery-order/modifyCustInfo`,
    method: 'POST',
    data
  })
}

// 订单详情
export function checkEbayOrderCode(orderCode) {
  return request({
    url: `/oms/returns/checkEbayOrderCode/${orderCode}`,
    method: 'get'
  })
}

// 订单日志查询
export function logsList(orderNumber) {
  return request({
    url: `/oms-job/logs/list/${orderNumber}`,
    method: 'get'
  })
}

export function transcationId(data) {
  return request({
    url: `/oms/self-delivery-order/transcationId`,
    method: 'POST',
    data
  })
}

// 供应商信息查询
export function queryVendorList() {
  return requestWms({
    url: `/base/basevendor/list`,
    method: 'get'
  })
}

// 获取所有position信息
export function getPosition() {
  return request({
    url: `/oms/review/getPosition`,
    method: 'get'
  })
}

// 退款原因列表
export function getReasonCodeList(data, params) {
  return request({
    url: `oms/returns/getReturnsReasonByPage`,
    method: 'POST',
    data,
    params
  })
}

// 根据平台获取退款原因
export function getReasonCodeByPlatform(platform) {
  return request({
    url: `oms/returns/getReturnsReasonByPlatform/${platform}`,
    method: 'get'
  })
}

// 新增退款原因
export function addReasonCode(data) {
  return request({
    url: `oms/returns/addReturnReason`,
    method: 'POST',
    data
  })
}

// 修改退款原因
export function updateReasonCode(data) {
  return request({
    url: `oms/returns/updateReturnReason`,
    method: 'POST',
    data
  })
}

// yyds-2396-网红详细信息
export function getDetailCelebrityById(params) {
  return request({
    url: `${process.env.VUE_APP_ERP_NEW_API}mos-web-core/celebrity/getDetailCelebrityById`,
    method: 'get',
    params
  })
}
// yyds-2396-关联网红营销任务
export function setCelebrityOrder(data) {
  return request({
    url: `${process.env.VUE_APP_ERP_NEW_API}mos-web-core/celebrity/setCelebrityOrder`,
    method: 'post',
    data
  })
}

// 自发货物流信息查询
export function getLogisticsInfo(data) {
  return request({
    url: `oms/order-17track/page`,
    method: 'post',
    data
  })
}

// 自发货物流信息的枚举值
export function getLogisticsEnum() {
  return request({
    url: `oms/order-17track/dict`,
    method: 'get'
  })
}

// 获取客户信息
export function getCustomerInfo(params) {
  return request({
    url: `oms/customer/getCustomerInfo`,
    method: 'get',
    params
  })
}

// 获取仓库信息
export function getWarehouseList(params) {
  return request({
    url: `oms-rest/orderInfo/defectiveItemWarehouse`,
    method: 'get',
    params
  })
}

// 新增/修改客户信息
export function addOrUpdateCustomerInfo(data = {}) {
  return request({
    url: `oms/customer/saveOrUpdateCustomerInfo`,
    method: 'POST',
    data
  })
}

// 导入SKU信息
export function importSku(data) {
  return request({
    url: `oms/sku/checkFile`,
    method: 'POST',
    data
  })
}

// temu-sku 导入
export function temuSkuImportExcel(data) {
  return request({
    url: `oms/temu-sku/importExcel`,
    method: 'POST',
    data
  })
}

// temu-sku 确认导入
export function temuSkuSureImportExcel(data, onUploadProgress = () => {
}) {
  return requestApi({
    url: `oms/temu-sku/sureImportExcel`,
    method: 'POST',
    data,
    onUploadProgress
  })
}

// 缺货
export function confirmNoStock(data) {
  return request({
    url: `oms/self-delivery-order/confirmNoStock`,
    method: 'post',
    data
  })
}
